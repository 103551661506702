import { renderToStaticMarkup } from 'react-dom/server'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

import { MARKETPLACES } from 'constants/countries'

import { formatMomentDate } from 'helpers/date'
import { translateMonth } from 'helpers/keyword'
import { parseCurrency } from 'helpers/currency'
import {
  PRODUCT_FOR_SEGMENT,
  sendSegmentFileDownloadedEvent
} from 'helpers/segment'

import { t } from 'services/i18n'
import { sendSegmentTrackEvent, safeSegmentCall } from 'services/segment'
import { downloadFile } from 'services/csv_export'

const getMin = min => {
  if (!min || Number.isNaN(parseInt(min, 10))) {
    return 0
  }
  return parseInt(min >= 10 ? min / 10 : min, 10)
}

const getMax = max => parseInt(max >= 11 ? max / 10 : max, 10)

export const getSeasonalityPeakMonthDisplay = (classification5, peakWeek) => {
  const peakMonth = peakWeek ? peakWeek.split(' ')[0] : ''
  const LOW = 2
  const VERY_LOW = 1
  return Number(classification5) === LOW || Number(classification5) === VERY_LOW
    ? 'No Peak Month'
    : translateMonth(peakMonth)
}

const getSeasonalityDisplay = (scoreValue, isMin) => {
  const value = isMin ? getMin(scoreValue) : getMax(scoreValue)
  switch (true) {
    case value <= 1:
      return 'Very Low'
    case value === 2:
      return 'Low'
    case value === 3:
      return 'Medium'
    case value === 4:
      return 'High'
    case value >= 5:
      return 'Very High'
    default:
      return null
  }
}

const getCompetitionDisplay = (scoreValue, isMin) => {
  const roundedValue = Math.round(scoreValue)
  const value = isMin ? getMin(roundedValue) : getMax(roundedValue)
  switch (true) {
    case value <= 2:
      return 'Very Low'
    case value <= 4:
      return 'Low'
    case value <= 6:
      return 'Medium'
    case value <= 8:
      return 'High'
    case value >= 9:
      return 'Very High'
    default:
      return null
  }
}

export const trackSearchOnSegment = (segmentEvent, filters = {}) => {
  const {
    avgUnitsSold,
    avgPrice,
    estimatedExactSearchVolume,
    monthlyTrend,
    quarterlyTrend,
    opportunityScore,
    competition,
    classification5
  } = filters
  safeSegmentCall(() => {
    const checkIfUserInput = value => (typeof value === 'string' ? value : null)
    sendSegmentTrackEvent(segmentEvent, {
      product: PRODUCT_FOR_SEGMENT,
      marketplace: MARKETPLACES[(filters.country?.valuesArray[0])]?.name,
      filters: [
        {
          type: 'Category',
          value: filters.category?.valuesArray.join('; ')
        },
        {
          type: 'Selected Filter Preset',
          value: filters.selectedPreset?.name
        }
      ],
      minAvgMthlyUnits: checkIfUserInput(avgUnitsSold?.min),
      maxAvgMthlyUnits: checkIfUserInput(avgUnitsSold?.max),
      minAvgMthlyPrice: checkIfUserInput(avgPrice?.min),
      maxAvgMthlyPrice: checkIfUserInput(avgPrice?.max),
      minMthlySearchVol: checkIfUserInput(estimatedExactSearchVolume?.min),
      maxMthlySearchVol: checkIfUserInput(estimatedExactSearchVolume?.max),
      minSearchVolTrend: checkIfUserInput(monthlyTrend?.min),
      maxSearchVolTrend: checkIfUserInput(monthlyTrend?.max),
      min90DaySearchVolTrend: checkIfUserInput(quarterlyTrend?.min),
      max90DaySearchVolTrend: checkIfUserInput(quarterlyTrend?.max),
      minNicheScore: checkIfUserInput(opportunityScore?.min),
      maxNicheScore: checkIfUserInput(opportunityScore?.max),
      minCompetition: getCompetitionDisplay(competition?.min),
      maxCompetition: getCompetitionDisplay(competition?.max),
      minSeasonality: getSeasonalityDisplay(classification5?.min),
      maxSeasonality: getSeasonalityDisplay(classification5?.max),
      includeKeywords: filters.query?.searchTerm,
      excludeKeywords: filters.eQuery?.searchTerm,
      excludeTopBrands: filters.excludeTopBrands
    })
  })
}

/**
 * @summary for some charts in Opp Finder, the backend returns the date as MMM YY instead of a timestamp, so we need to specially format to support multi-languages
 * @param {String} mmmYyDate - string date in the format "MMM YY", ex: "Mar 19"
 * @return {Moment} - "March 2019"
 */
export const formatMonthYearTooltipDate = mmmYyDate => {
  return moment(mmmYyDate, 'MMM YY', 'en') // must pass in 'en' locale as some languages don't recognize MMM YY format
    .locale(moment.locale()) // convert back to current locale
    .format('MMMM YYYY')
}

function extractRangeValues(values) {
  if (!values || (values.min === undefined && values.max === undefined)) {
    return false
  }
  const { min, max } = values
  const result = {}

  if (min !== undefined) {
    result.min = min
  }
  if (max !== undefined) {
    result.max = max
  }
  return result
}

/**
 * @summary adjust params to match the get_keywords API design.
 * @param params
 * @returns modified params object
 */
export const formatOppFinderApiParams = params => {
  const { paginate, country, sort, category, eQuery, query } = params

  const apiParams = { country: country?.valuesArray?.[0] || 'us' }
  const filters = {}

  const rangeFilter = [
    'avgPrice',
    'avgUnitsSold',
    'classification5',
    'competition',
    'estimatedExactSearchVolume',
    'opportunityScore',
    'monthlyTrend',
    'quarterlyTrend'
  ]
  rangeFilter.map(value => {
    const filter = params[value]
    if (isEmpty(filter)) {
      return false
    }
    filters[value] = extractRangeValues(filter)
    return true
  })

  if (paginate) {
    apiParams.page = { size: paginate.pageSize, from: paginate.from }
  }
  if (sort) {
    apiParams.sort = { column: sort.column, direction: sort.direction }
  }
  if (category) {
    filters.categories = category.valuesArray
  }
  if (eQuery) {
    filters.excludeKeywords = [eQuery.searchTerm]
  }
  if (query) {
    filters.includeKeywords = [query.searchTerm]
  }

  if (!isEmpty(filters)) {
    apiParams.filters = filters
  }

  return apiParams
}

const getMarkup = value =>
  renderToStaticMarkup(value).replace(/(<([^>]+)>)/gi, '')

const tableHeaderToCSVColumn = column => {
  const name = getMarkup(column.Header)
  const subName = getMarkup(column.subHeader)

  return {
    name: subName ? `${name} - ${subName}` : name,
    accessor: column.accessor
  }
}

const toCSVTextContent = (values, columns, getValue) => {
  const headers = columns.map(column => column.name).join(',')
  const rows = values.map(value =>
    columns.map(column => `"${getValue(value, column)}"`).join(',')
  )
  return [headers, ...rows].join('\n')
}

const getCSVOppFinderColumns = columns =>
  columns
    .map(column => {
      // Expands columns that have multiple values in their cells

      if (column.name === 'Opportunity - Dominant Category') {
        return [
          {
            name: 'Opportunity',
            accessor: column.accessor
          },
          {
            name: 'Dominant Category',
            accessor: 'category'
          }
        ]
      }

      if (column.name === 'Seasonality') {
        return [
          {
            name: column.name,
            accessor: column.accessor
          },
          {
            name: 'Seasonality - Peak Month',
            accessor: 'peakWeek'
          }
        ]
      }

      return column
    })
    .flat()

const getCSVOppFinderValue = currencyCode => (keyword, column) => {
  const cellValue = keyword[column.accessor]

  switch (column.accessor) {
    case 'opportunityScore':
      return Math.round(parseInt(cellValue, 10) / 10)
    case 'avgUnitsSold':
      return parseFloat(cellValue).toLocaleString('en-US')
    case 'avgPrice':
      return parseCurrency(cellValue, currencyCode)
    case 'estimatedExactSearchVolume':
      return parseFloat(cellValue).toLocaleString('en-US')
    case 'monthlyTrend':
      return `${parseInt(cellValue, 10)}%`
    case 'quarterlyTrend':
      return `${parseInt(cellValue, 10)}%`
    case 'competition':
      return getCompetitionDisplay(cellValue / 10)
    case 'classification5':
      return getSeasonalityDisplay(cellValue)
    case 'peakWeek':
      return (
        getMarkup(
          getSeasonalityPeakMonthDisplay(keyword.classification5, cellValue)
        ) ||
        t(
          'keyword_engine:OpportunityFinderTableV2.classification5.noPeakMonth',
          'No Peak Month'
        )
      )
    case 'updatedAt':
      return formatMomentDate(cellValue, 'MMM DD, YYYY') || '-'
    default:
      return cellValue
  }
}

export const csvOpportunityFinderExport = (keywords, columns, currencyCode) => {
  const dateObject = new Date(Date.now())
  const dateStr = moment(dateObject).format('YYYY-MM-DD h:m:s')

  const csvColumns = columns.map(tableHeaderToCSVColumn)
  const oppFinderColumns = getCSVOppFinderColumns(csvColumns)

  const fileName = `Jungle Scout Opportunity Finder CSV Export - ${dateStr}.csv`
  const message = `JUNGLESCOUT OPPORTUNITY FINDER CSV EXPORT\nReport Generated at: ${dateObject}\n`

  const csvContent =
    message +
    toCSVTextContent(
      keywords,
      oppFinderColumns,
      getCSVOppFinderValue(currencyCode)
    ).replace(/,$/, '\n')

  const mimetype = `data:text/csv;charset=UTF-8,${encodeURIComponent(
    csvContent
  )}`

  downloadFile(fileName, mimetype)

  sendSegmentFileDownloadedEvent(
    'Opportunity Finder',
    'Opportunity Finder CSV Export.csv'
  )
}
